/* //Common form css */
@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=DM+Sans:opsz@9..40&family=Jost:wght@500&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@600&family=Pacifico&family=Poppins:wght@500&display=swap");

* {
  font-family: "Jost", sans-serif;
}
.common_component_form {
  display: flex;
  flex-direction: row;
  gap: 11px;
}
.common_file_notification {
  font-size: 45px;
  color: rgb(237 110 104);
}
/* .navbar_content {
  margin-top: 2%;
} */
.common_inventory_heading {
  font-family: jost;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}

/* //All tabs main content css */
.project-tab {
  padding: 10%;
  margin-top: -8%;
}
.project-tab #tabs {
  background: #007b5e;
  color: #eee;
}
.project-tab #tabs h6.section-title {
  color: #eee;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0062cc;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid !important;
  font-size: 16px;
  font-weight: bold;
}
.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #0062cc;
  font-size: 16px;
  font-weight: 600;
}
.project-tab .nav-link:hover {
  border: none;
}
.project-tab thead {
  background: #f3f3f3;
  color: #333;
}
.project-tab a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}
.inventory_tabs_main_div {
  margin-top: 2%;
}
/* .item_tabs_main_container {
  background-color: white;
  border-radius: 10px;
} */
.MuiBox-root.css-1gsv261 {
  border-bottom: none !important;
  /* padding-inline: 20px ; */
}
th {
  font-family: "Jost", sans-serif !important;
  font-weight: 500 !important;
}
td {
  font-family: "Jost", sans-serif !important;
  font-weight: 400 !important;
}
.MuiBox-root.css-19kzrtu {
  padding: 0 !important;
  margin-top: 40px !important;
}
.inventory_all_tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  gap: 10px;
  border-radius: 9px;
  /* padding: 10px 0; */
  align-items: center;
  padding: 10px 0px;
}
.filters_inventory {
  margin-top: 9px;
  margin-right: 10px;
  display: flex;
  /* width: 20%; */
  justify-content: space-between;
}
.filters_inventory_div {
  margin-top: 9px;
  margin-right: 10px;
}

.payment_select_tag .ant-select-selector {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
}
.payment_select_tag {
  margin-right: 50px;
}
.all_tabs_value {
  margin-left: 20px;
}
.filter_btn_invontry {
  font-family: jost;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgba(225, 37, 27, 1) !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: rgba(225, 37, 27, 1) !important;
}
.table_pagination_sales_order {
  display: flex;
  justify-content: end;
  padding: 1px;
}
.user_iconses {
  margin-right: 12px;
}
#search_bar {
  width: 35%;
  margin-left: 90px;
}
.show_span_edit {
  margin-left: 5px;
}
.fa_filter_rating_review a {
  text-decoration: none;
}
.tabs_main_contnet {
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: "Jost", sans-serif;
  color: #263238 !important;
}

/* Inventory Edit page css */
.Inventory_edit_page_main_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.dataBase_table_Add_Data {
  margin-top: 25px;
}
.invetory_title_div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inventory_form_main_contnet {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inventory_edit_submit_btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.inventory_save_button {
  width: 20%;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-family: "Jost", sans-serif !important;
}
.inventory_edit_page_heading {
  font-family: jost;
  font-size: 26px;
  line-height: 37px;
  font-weight: 700;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 150px !important;
}
/* //DeltePopUp  */
.show_span {
  margin-left: 10px;
}
.show_span_edit {
  margin-left: 17px;
}
.my_reply_select_tag {
  height: 54px;
  border-radius: 28px;
}
.my_input_font_size {
  font-size: 14px !important;
}
.my_enqiry_form_text_area {
  border-radius: 28px;
}
.myMainDrawerClass {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 25px;
}
.my_delete_popup_paragraph {
  padding-inline: 30px;
}
.myDialogPopUp {
  border-radius: 10px;
  width: 40%;
}
.table-bordered > :not(caption) > * > * {
  padding: 10px 15px;
  font-size: 14px;
  font-family: "Jost";
}
.table-bordered > :not(caption) > * > * {
  border-width: 0px !important;
}
tr,
th,
td {
  border-bottom: 1px solid #f5f5f5 !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
}

/* inventory edit page popup */
.inventory_edit_page_popup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 13px;
}
.inventory_popup_create_promocodes {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.inventory_upload_file_main_div {
  border: 1px solid rgba(235, 235, 235, 1);
  border-radius: 6px;
}
.inventory_edit_upload {
  padding: 12px;
}
.inventroy_edit_page_antd_uplaod_button {
  border: 0;
  background: none;
}
.inventroy_edit_page_antd_choose_file {
  font-family: jost;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ant-upload.ant-upload-select {
  height: 35px !important;
  /* margin-top: 25px !important; */
  border: none !important;
  padding: 19px !important;
  width: 25% !important;
  background-color: rgba(236, 236, 236, 1) !important;
}

.inventory_open_popup_action_btn {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 22px;
  margin-bottom: 10px;
}
.inventory_open_popup_action_btn button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 23px; */
  padding: 14px;
  width: 23%;
}
.inventory_popup_title_heading {
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Jost" !important;
  line-height: 4px !important;
}
.inventory_popup_title {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 22px;
}

/* //Inventory Filter Page */
/* .inventory_filter_div {
  margin-top: 10px;
} */
/* //Inventory Button */
.inventory_vendor_add_new_btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: none !important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-select .ant-select-arrow {
  color: #000;
}
/* // For miui tab */
.css-1q2h7u5.Mui-selected {
  color: red !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 24.57px !important;
  font-family: "Jost" !important;
}
.css-ttwr4n {
  background-color: red !important;
}
.css-1q2h7u5 {
  text-transform: capitalize !important;
}
/* :where(.css-dev-only-do-not-override-dkbvqv).ant-picker:hover {
  border-color: rgba(119, 119, 119, 1);
} */
/* // miui tabs end */
/* //Delete popup media query */
/* // Inventory popup tabs section */
.inventory_upload_excel_section label {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-bottom: 8px;
}
.categories_open_popup_main_contnet span {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
}
.sign_up_file_upload_for_inventory {
  border: 2px solid #dee2e6;
  border-radius: var(--bs-border-radius);
  background: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 2px;
  padding-inline: 14px;
}
.sign_up_file_upload_for_inventory .ant-upload-wrapper .ant-upload-select {
  height: unset !important;
  margin-top: unset !important;
  border: unset !important;
  padding: unset !important;
  width: unset !important;
  background: unset !important;
}
.sign_up_file_upload_for_inventory button {
  padding: 2px 29px;
  background: #2697ff;
  padding: 8px 20px !important;
  color: #fff;
  border-radius: 25px;
  height: unset;
  display: flex;
  flex-direction: row-reverse;
  gap: 13px;
}
.sign_up_file_upload_for_inventory button .ant-btn-icon svg {
  text-align: center;
  align-items: center;
  margin-top: 2px;
}
.sign_up_file_upload_for_inventory button:hover {
  padding: 2px 29px;
  background: #2697ff;
  color: #fff !important;
  border-radius: 25px;
  height: unset;
}
.frisbee_dashbaord_inventory_popup_tabs
  .MuiTabs-scroller
  .MuiTabs-flexContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.frisbee_dashbaord_inventory_popup_tabs .MuiTabs-scroller .MuiTabs-indicator {
  display: none;
}
.vendor_dashbaord_signle_product {
  display: flex;
  gap: 13px;
}
.vendor_dashbaord_signle_product input {
  width: 20px;
  color: rgba(48, 194, 35, 1);
}
.vendor_dashboard_radio_color input[type=radio] {
  border-color: rgba(48, 194, 35, 1) !important;
  background-color: rgba(48, 194, 35, 1) !important;
}
.vendor_dashboard_radio_color input[type="radio"]:checked {
  background-color: rgba(48, 194, 35, 1) !important;
}
.frisbee_dashbaord_pop_up_tabs1 {
  text-transform: capitalize !important;
  font-family: "Jost", sans-serif !important;
  line-height: 20.23px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  border: 2px solid #dee2e6 !important;
  border-radius: 7px !important;
}
.company_registration_document_button {
  color: #c3c3c3;
}
.inventory_open_popup_action_btn_upload_main {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 22px;
  margin-bottom: 10px;
}
.inventory_open_popup_action_btn_upload_main button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 23px; */
  padding: 14px;
  width: 40%;
}
.inventory_select_opetion_popup_modal {
  margin-bottom: 8px;
}
.vendor_popup_product_name {
  font-weight: 600;
  font-size: 16px;
  line-height: 23.12px;
  /* margin-bottom: 8px; */
}

.vendor_Add_edit_data_form input::placeholder {
  color: #000;
}
.vendor_Add_edit_data_form input:focus {
  box-shadow: none;
  border: 1px solid black;
}
.including_uat_vendor {
  color: rgba(135, 135, 135, 1);
  font-weight: 500 !important;
  font-size: 13px !important;
}
.all_input_tag_tagenter_product_:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}
.all_top_and_bottom_for_input_text {
  margin-top: 15px;
  margin-bottom: 5px;
}
.product_inventory_edit_upload {
  margin-left: 20px;
}
.product_inventory_edit_upload.ant-upload-select {
  height: 35px !important;
  margin-top: 25px !important;
  border: none !important;
  padding: 19px !important;
  width: 40% !important;
  background-color: rgba(236, 236, 236, 1) !important;
}
.product_inventory_edit_upload:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  height: 45px !important;
  margin-top: 10px;
}
.all_top_and_bottom_for_input_text {
  margin-top: 15px;
  margin-bottom: 5px;
}
.vendor_dashbaord_gender_main_section {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
  padding: 3px;
}

.vendor_dashbaord_gender_main_section input:checked {
  background-color: red;
  border-color: red;
}
.img_adoptionsss{
  max-width: 90px;
  max-height: 50px;
}
/* // 26-07-2024 here is new css for all input, label and button */
.all_lable_for_vendor_dashbaord {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.store_input_field_field:focus {
  box-shadow: none;
  border: 1px solid black;
}
.inventory_filter_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inventory_filter_div img {
  width: 25px;
}
.all_border_bottom_for_hide {
  border-bottom: none !important;
}
.all_button_cancel_and_submit{
  margin-top: 21px;
  display: flex;
  gap: 15px;
}
@media only screen and (max-width: 768px) {
  .myDialogPopUp {
    width: 70%;
  }
}
/* //Edit page media query */
@media only screen and (max-width: 768px) {
  .inventory_save_button {
    width: 50%;
  }
  .inventory_edit_page_heading {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .inventory_all_tabs {
    flex-wrap: wrap !important;
  }
  .filters_inventory {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .filters_inventory svg {
    margin: 5px;
  }
  .payment_select_tag {
    margin-right: 15px;
  }
  .filters_inventory_div {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  /* //Add new css */
  .inventory_tabs_main_div {
    margin-top: 3%;
    /* margin-right: 35px; */
  }
  #search_bar {
    width: 93%;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 425px) {
  #search_bar {
    width: 93%;
    margin-left: 10px;
  }
  .common_inventory_heading {
    font-size: 18px;
  }
  .all_tabs_value {
    margin-left: 0px;
  }
  .css-heg063-MuiTabs-flexContainer {
    overflow: scroll;
    flex-wrap: wrap;
  }
  .inventory_filter_div {
    margin-left: 15px;
  }
}
