.filters_inventory_Rating_review {
  /* margin-top: 9px; */
  margin-right: 10px;
  display: flex;
  /* width: 20%; */
  justify-content: space-between;
}
.filter_btn_invontry_rating_review {
  font-family: jost;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.payment_select_tag_rating_review .ant-select-selector {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
}

.fa_filter_rating_review {
  margin-right: 20px;
}
.all_tabs_value_rating_review {
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  /* .filters_inventory_Rating_review {
    margin-left: 20px;
    margin-bottom: 13px;
  }
  .all_tabs_value_rating_review {
    margin-left: 0px;
  }
  .all_tabs_value_rating_review {
    max-width: 100%;
  }
  .all_tabs_value_rating_review button {
    padding: 0;
    margin-right: -5px;
  } */
  .all_tabs_value_rating_review {
    margin-left: 4px;
    display: flex;
    width: 100%;
    overflow: scroll !important;
  }
  .filters_inventory_Rating_review {
    margin-left: 20px;
    margin-bottom: 13px;
}
}
@media only screen and (max-width: 425px) {
  .all_tabs_value_rating_review button {
    padding: 6px;
    overflow: scroll !important;
  }
}
