.categories_tb_select {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
  border-radius: 5px;
  padding: 3px;
}
.categories_main_div {
  display: flex;
  justify-content: space-between;
}
/* ///// */
.categories_card_box_for_pet {
  border: 1px solid #ddd;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
  background-color: white;
}
.img-fluid {
  width: 100%;
}
.img-fluid {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.card-thumbnail {
  overflow: hidden;
}
.img-fluid:hover {
  transform: scale(1.2);
}
.categories_card_box_for_pet.card-thumbnail:hover {
  transform: scale(1.2);
}
.categories_card_box_for_pet h3 a {
  font-size: 20px;
  text-decoration: none;
}
.cotegories_pet_food {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.cotegories_about_food_description {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(123, 123, 123, 1);
}
.catogries_create_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cartegories_created_on {
  font-family: "Jost";
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: rgba(38, 50, 56, 1);
}
.cartegories_created_on_date {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(38, 50, 56, 1);
}
.catogries_create__switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.categories_switch_.ant-switch.ant-switch-checked {
  background-color: rgba(48, 194, 35, 1);
}
.categories_switch_.ant-switch.ant-switch-checked:hover {
  background-color: rgba(48, 194, 35, 1);
}
/* // PopUp moudle css */
.categories_popup_title {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 5px;
}
.categories_popup_title_heading {
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Jost" !important;
  line-height: 31px !important;
}
/* .categories_popup_title.css-h93ljk-MuiTypography-root{
  font-family: jost !important;
} */
.categories_open_popup_form {
  display: flex;
  flex-direction: column;
  /* gap: 23px; */
  margin-bottom: 20px;
}
.categories_popup_dialog_modal {
  width: 50% !important;
  border-radius: 16px !important;
}
.categories_open_popup_action_btn {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 70px;
  margin-bottom: 10px;
}
.categories_open_popup_action_btn button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 23px; */
  padding: 14px;
  width: 23%;
}
.categories_open_popup_main_contnet {
  width: 100%;
}
.categories_open_popup_label_name {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  /* margin-bottom: 15px; */
}
.categories_open_popup_label_name_image {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 15px;
}
/* .categories_modal_popUp {
}
.categories_palceholder_name {
} */
.categories_select_tg_enter_product {
  font-size: 14px;
  font-family: "Jost";
  padding: 15px;
}
.categories_select_tg_enter_product:focus {
  border: 1px solid black;
  box-shadow: none;
}
/* .categories_cancel_button{
  background-color: 
  rgba(38, 50, 56, 1);
} */
.vendor_card_services_img {
  text-align: center;
}
.vendor_card_services_img img {
  max-width: 170px;
  object-fit: cover;
  height: auto;
}
.categroies_image_servies {
  text-align: center;
}
.categroies_image_servies img {
  max-width: 170px;
  object-fit: cover;
  height: auto;
  /* width: 50%; */
  max-height: 145px;
  object-fit: cover;
  margin: 0px 25%;
}
@media only screen and (max-width: 768px) {
  .categories_popup_dialog_modal {
    width: 80% !important;
  }
  .categories_open_popup_action_btn button {
    font-size: 12px;
    width: 100%;
  }
  .categories_open_popup_action_btn {
    margin-top: 18px;
  }
}
@media only screen and (max-width: 425px) {
  .categories_popup_title {
    margin-bottom: 14px;
    margin-top: 15px;
  }
  .categories_popup_title_heading {
    font-size: 18px !important;
  }
  .categories_popup_dialog_modal {
    width: 80% !important;
  }
  .categories_open_popup_action_btn button {
    width: 100%;
    font-size: 12px;
    padding: 10px;
  }
  .categories_open_popup_action_btn {
    margin-top: 20px;
  }
  .request_new_categories {
    font-size: 10px;
  }
}
