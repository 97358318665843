.order_now_himalays {
  text-decoration: none !important;
  font-size: 15px;
  font-family: jost;
  line-height: 21px;
  font-weight: 400;
}
.orders_now_image {
  max-width: 115px;
}
.order_now_qty {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  margin: 10px 0px;
}
.orders_now_table tr {
  border-bottom: 2px solid #eee;
}
.orders_now_billing_details_heading {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-left: 6px;
}
.booking_details_billing_details_heading {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
}
.orders_now_subtotal {
  font-family: "Jost" !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(0, 0, 0, 1) !important;
}
.order_now_rupees {
  font-family: "Jost" !important;
  font-size: 15px;
  font-weight: 400 !important;
  line-height: 21.48px;
}
.order_now_address {
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-top: 10px;
  gap: 13px;
  margin-bottom: 0px;
}
.order_now_address address {
  margin-bottom: 0rem !important;
}
.order_now_address span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(85, 85, 85, 1);
  margin-bottom: 0rem !important;
}
.booking_deatils_order_now_address {
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.booking_deatils_order_now_address span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(85, 85, 85, 1);
  margin-bottom: 0rem !important;
}
.order_now_card_tilte {
  margin-bottom: 30px;
  margin-top: 10px;
  margin-top: 2%;
}
.order_now_address_date_and_time {
  margin-left: 8px;
  margin-top: 8px;
}

.order_now_date_and_time_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.order_now_heading_button {
  margin-top: 15px;
}
.order_now_heading_button1 {
  background-color: rgba(236, 245, 238, 1);
  color: rgba(44, 200, 74, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  margin-right: 15px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.order_now_heading_button1 svg {
  margin-bottom: 2px;
}
.order_now_heading_button2 {
  background-color: rgba(255, 242, 243, 1);
  color: rgba(209, 26, 42, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 10px;
}
.order_now_heading_button2 svg {
  margin-bottom: 2px;
}
.order_now_billing_details_border {
  border-bottom: 2px solid #eee;
}
.booking_details_top_icon svg {
  font-size: 13px;
}
.booking_deatils_heading {
  font-size: 16px;
  font-weight: 400;
  margin-left: 3px;
}
.order_now_address_date_and_time span {
  font-family: "Jost" !important;
  font-weight: 500;
  font-size: 13px;
  line-height: 18.79px;
  color: rgba(153, 153, 153, 1);
}
.booking_deatils_table_for_tr {
  margin-bottom: 0rem;
}
.booking_deatils_table_for_tr tr,
td {
  border-bottom: none !important;
}
.booking_details_convenience {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.23px;
  color: rgba(153, 153, 153, 1);
}
.booking_details_payable_amount {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 23.12px;
}
.booking_details_status_button {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.booking_now_heading_button1 {
  background-color: rgba(236, 245, 238, 1);
  color: rgba(44, 200, 74, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  margin-right: 15px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 50%;
}
.booking_now_heading_button2 {
  background-color: rgba(255, 242, 243, 1);
  color: rgba(209, 26, 42, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 50%;
}
.booking_details_assign_vendor {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.booking_details_dog_star {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: rgba(85, 85, 85, 1);
}
.booking_now_IoArrow {
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 6px;
  width: 6%;
  height: 33px;
}
.enquiry_details_main_div {
  display: flex;
  width: 100%;
  margin-top: 10px;
  /* justify-content: space-between; */
}
.enquiry_details_main_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 15px;
}
.enquiry_details_main_breed {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.enquiry_details_main_breed span {
  font-family: jost;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.23px;
}
@media only screen and (max-width: 500px) {
  .order_now_heading_button1 {
    margin-bottom: 10px;
  }
}
